export const getHorseProfileData = /* GraphQL */ `
	query getHorseProfile($horseCode: Int!) {
		getHorseProfile(horseCode: $horseCode) {
			horse {
				horseCode
				name
				status
				sex
				age
				country
				colour
				sireName
				damName
				registrationNumber
				foalDate
				foalYear
				microChipNumber
				nameOverseas
				lifeNumber
				ottInformation {
					paddockName
					profilePhoto {
						key
					}
					uploadedFiles {
						activeProfilePicture
						type
						id
						key
						filename
						removed
					}
					disciplines {
						value
					}
					disciplinesOther
					habits {
						value
					}
					habitsOther
					happyDetails
					hasIllnesses
					hasInjuries
					illnesses {
						value
					}
					illnessesOther
					injuries {
						value
					}
					injuriesOther
					personalityDetails
					purpose {
						value
					}
					purposeOther
					disciplinesVisibility {
						id
						value
					}
					habitsVisibility {
						id
						value
					}
					happyDetailsVisibility {
						id
						value
					}
					illnessesVisibility {
						id
						value
					}
					injuriesVisibility {
						id
						value
					}
					profilePhotoVisibility {
						id
						value
					}
					paddockNameVisibility {
						id
						value
					}
					purposeVisibility {
						id
						value
					}
					personalityDetailsVisibility {
						id
						value
					}
					purpose {
						id
						value
					}
					purposeOther
				}
				location {
					address
					country
					postcode
					stabledDate
					state
					suburb
					source {
						value
					}
					sourceOther
					principalRacingAuthorityDetails {
						principalRacingAuthority
						principalRacingAuthorityOther
						principalRacingAuthorityValue
					}
					addressVisibility {
						id
						value
					}
					postcodeVisibility {
						id
						value
					}
					countryVisibility {
						id
						value
					}
					stateVisibility {
						id
						value
					}
					suburbVisibility {
						id
						value
					}
					stabledDateVisibility {
						id
						value
					}
				}
				racingSummary {
					raceTypeSummary {
						bonus
						career
						prizeMoney
						starts
					}
					classSummary {
						class
						firsts
						prizeMoney
						seconds
						starts
						thirds
					}
				}
			}
			numberOfFollowers
			program {
				id
				value
			}
			status {
				status {
					value
				}
				conclusionReason {
					id
					value
				}
				conclusionPurpose {
					id
					value
				}
				conclusionPurposeOther
				effectiveDate
			}
			claims {
				member {
					firstName
					lastName
					id
					externalId
				}
				id
				ownershipType
				createDatetime
				claimsSource
				claimsSourceOther
			}
			unclaims {
				id
				member {
					firstName
					lastName
				}
				ownershipType
				created
			}
			fullCircles {
				id
			}
		}

		getResetNominationForms(horseCode: $horseCode, status: [Review]) {
			id
		}
		getHorseProfileRetirement(horseCode: $horseCode) {
			id
			memberId
			horseCode
			retirementCategory
			behaviourOthersRetirement
			conformationalDetailsRetirement
			otherEducationDetails
			otherInjuryDetailsRetirement
			otherIllnessDetailsRetirement
			rehomingHistoryDetails
			otherDetailsRetirement
			vulnerabilityType
			habits {
				id
				value
			}
			injuries {
				id
				value
			}
			illness {
				id
				value
			}
			education {
				id
				value
			}
			reason {
				id
				value
			}
		}
		listHorseClaimSources {
			id
			value
		}
	}
`;

export const getHorseProfileDataUnauthenticated = /* GraphQL */ `
	query getHorseProfile($horseCode: Int!) {
		getHorseProfile(horseCode: $horseCode) {
			horse {
				horseCode
				name
				status
				sex
				age
				country
				colour
				sireName
				damName
				registrationNumber
				foalDate
				foalYear
				microChipNumber
				nameOverseas
				lifeNumber
				ottInformation {
					paddockName
					profilePhoto {
						key
					}
					uploadedFiles {
						activeProfilePicture
						type
						id
						key
						filename
						removed
					}
					disciplines {
						value
					}
					disciplinesOther
					habits {
						value
					}
					habitsOther
					happyDetails
					hasIllnesses
					hasInjuries
					illnesses {
						value
					}
					illnessesOther
					injuries {
						value
					}
					injuriesOther
					personalityDetails
					purpose {
						value
					}
					purposeOther
					disciplinesVisibility {
						id
						value
					}
					habitsVisibility {
						id
						value
					}
					happyDetailsVisibility {
						id
						value
					}
					illnessesVisibility {
						id
						value
					}
					injuriesVisibility {
						id
						value
					}
					profilePhotoVisibility {
						id
						value
					}
					paddockNameVisibility {
						id
						value
					}
					purposeVisibility {
						id
						value
					}
					personalityDetailsVisibility {
						id
						value
					}
					purpose {
						id
						value
					}
					purposeOther
				}
				location {
					address
					country
					postcode
					stabledDate
					state
					suburb
					source {
						value
					}
					sourceOther
					addressVisibility {
						id
						value
					}
					postcodeVisibility {
						id
						value
					}
					countryVisibility {
						id
						value
					}
					stateVisibility {
						id
						value
					}
					suburbVisibility {
						id
						value
					}
					stabledDateVisibility {
						id
						value
					}
				}
				racingSummary {
					raceTypeSummary {
						bonus
						career
						prizeMoney
						starts
					}
					classSummary {
						class
						firsts
						prizeMoney
						seconds
						starts
						thirds
					}
				}
			}
			numberOfFollowers
			program {
				id
				value
			}
			status {
				status {
					value
				}
				conclusionReason {
					id
					value
				}
				conclusionPurpose {
					id
					value
				}
				conclusionPurposeOther
				effectiveDate
			}
			claims {
				member {
					firstName
					lastName
					id
					externalId
				}
				id
				ownershipType
				createDatetime
				claimsSource
				claimsSourceOther
			}
			unclaims {
				id
				member {
					firstName
					lastName
				}
				ownershipType
				created
			}
			fullCircles {
				id
			}
		}

		getResetNominationForms(horseCode: $horseCode, status: [Review]) {
			id
		}
	}
`;

export const getHorseDataForUser = /* GraphQL */ `
	query getHorseDataForUser($memberExternalId: String!, $horseCode: Int!) {
		getMember(externalId: $memberExternalId) {
			id
			primaryMembershipType {
				name
			}
			externalId
		}
		isClaimedByMember(
			memberExternalId: $memberExternalId
			horseCode: $horseCode
		)
		getExistingNonRejectedForms(
			memberExternalId: $memberExternalId
			horseCode: $horseCode
		) {
			formName
			nonRejectedFormExists
		}
		getFullCircles(
			horseCode: $horseCode
			memberExternalId: $memberExternalId
			status: [Approved, Reviewed]
		) {
			id
		}
		getOhepNominationForm(horseCode: $horseCode) {
			status
		}
	}
`;

export const followHorse = /* GraphQL */ `
	mutation followHorse($horseCode: Int!) {
		createPrpHorseFollows(horseCode: $horseCode) {
			horse_code
		}
	}
`;

export const checkIfFollowingHorse = /* GraphQL */ `
	query checkIfFollowingHorse($member_id: Int!, $horse_code: Int!) {
		getPrpHorseFollows(horse_code: $horse_code, member_id: $member_id) {
			horse_code
		}
	}
`;

export const unfollowHorse = /* GraphQL */ `
	mutation unfollowHorse($horseCode: Int!) {
		deletePrpHorseFollows(horseCode: $horseCode) {
			horse_code
		}
	}
`;

export const getHorseUpdates = /* GraphQL */ `
	query getHorseUpdates(
		$horseCode: Int!
		$memberId: Int!
		$paginationIndex: Int!
		$paginationSize: Int!
	) {
		getUpdatesForHorse(
			horseCode: $horseCode
			memberId: $memberId
			paginationIndex: $paginationIndex
			paginationSize: $paginationSize
		) {
			created_at
			horse_code
			message
			post_id
			files {
				key
			}
			postingMember {
				externalId
			}
			source
		}
	}
`;

export const countHorseUpdates = /* GraphQL */ `
	query countHorseUpdates($horseCode: Int!, $memberId: Int!) {
		countUpdatesForHorse(horseCode: $horseCode, memberId: $memberId)
	}
`;

export const getHorseRelatedForms = /* GraphQL */ `
	query getHorseRelatedForms($status: [FormStatusEnum!], $horseCode: Int) {
		getRetirementInitialAssessmentForms(
			status: $status
			horseCode: $horseCode
		) {
			id
			member {
				id
				externalId
				email
				firstName
				lastName
			}
			horse {
				name
				horseCode
			}
			lastUpdated
			created
			created
		}
		getHorsePlacementFormsByHorseCode(status: $status, horseCode: $horseCode) {
			id
			member {
				externalId
				firstName
				lastName
			}
			status
			horse {
				name
			}
			lastUpdated
			created
		}
		getResetNominationForms(status: $status, horseCode: $horseCode) {
			id
			member {
				externalId
				firstName
				lastName
			}
			status
			horse {
				name
				ottInformation {
					paddockName
				}
			}
			lastUpdated
			created
		}
		getResetInitialAssessmentForms(status: $status, horseCode: $horseCode) {
			id
			member {
				externalId
				firstName
				lastName
			}
			status
			horse {
				name
				ottInformation {
					paddockName
				}
			}
			lastUpdated
			created
		}
		getResetExpressInitialAssessmentForms(
			status: $status
			horseCode: $horseCode
		) {
			id
			member {
				externalId
				firstName
				lastName
				id
			}
			status
			horse {
				name
				ottInformation {
					paddockName
				}
			}
			lastUpdated
			created
		}
		getResetExpressRehomeAssessmentForms(
			status: $status
			horseCode: $horseCode
		) {
			id
			member {
				externalId
				firstName
				lastName
			}
			status
			lastUpdated
			created
			horse {
				name
				ottInformation {
					paddockName
				}
			}
		}
		getResetExpressRehabilitationConclusionForms(
			status: $status
			horseCode: $horseCode
		) {
			id
			member {
				externalId
				firstName
				lastName
			}
			status
			lastUpdated
			created
			horse {
				name
				ottInformation {
					paddockName
				}
			}
		}
		getFullCircles(status: $status, horseCode: $horseCode) {
			id
			status
			lastUpdated
			createDatetime
			member {
				firstName
				lastName
			}
			horse {
				name
				horseCode
			}
		}
		getFosterInitialAssessmentForms(status: $status, horseCode: $horseCode) {
			id
			member {
				id
				externalId
				email
				firstName
				lastName
			}
			horse {
				name
				horseCode
			}
			lastUpdated
			created
		}
		getShareInformationForms(status: $status, horseCode: $horseCode) {
			id
			member {
				externalId
				firstName
				lastName
			}
			status
			horse {
				name
			}
			lastUpdated
			created
		}
		getOhepNominationForms(status: $status, horseCode: $horseCode) {
			id
			status
			horse {
				name
			}
			member {
				firstName
				lastName
				id
			}
			lastUpdated
			created
		}
	}
`;
